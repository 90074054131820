import './styles/main.scss';
import Home from './screens/Home';
import { useRecoilState } from 'recoil';
import { correctAnswerState, currentIndexState, screenState } from './state';
import Questions from './screens/Questions';
import { useEffect, useRef, useState } from 'react';
import { usePrevious } from './utils';
import Result from './screens/Result';

function App() {
  const [scale, setScale] = useState(1);
  const containerRef = useRef(null);
  const [screen] = useRecoilState(screenState);
  const prevScreen = usePrevious(screen);
  const [correctAnswer] = useRecoilState(correctAnswerState);
  const prevCorrectAnswer = usePrevious(correctAnswer);
  const [questionIndex] = useRecoilState(currentIndexState);
  const prevQuestionIndex = usePrevious(questionIndex);

  const video1Ref = useRef(null);
  const video2Ref = useRef(null);
  const video3Ref = useRef(null);
  const video4Ref = useRef(null);
  const video5Ref = useRef(null);
  const video6Ref = useRef(null);
  // const video7Ref = useRef(null);
  // const video8Ref = useRef(null);
  // const video9Ref = useRef(null);
  // const video10Ref = useRef(null);

  function scaleContainer() {
    //scale the container to fit inside browser view
    const scale = Math.min(window.innerWidth / containerRef.current.offsetWidth, window.innerHeight / containerRef.current.offsetHeight);
    setScale(scale);
  }

  useEffect(() => {
    setTimeout(() => {
      scaleContainer();
      window.addEventListener('resize', scaleContainer);
    }, 400);
  }, []);

  const currentVideo = () => {
    switch (screen) {
      case 'home':
        return video1Ref.current;
      case 'questions':
        switch (questionIndex) {
          case 0:
            return video1Ref.current;
          case 1:
            return video2Ref.current;
          case 2:
            return video3Ref.current;
          case 3:
            return video4Ref.current;
          case 4:
            return video5Ref.current;
          case 5:
            return video6Ref.current;
          // case 6:
          //   return video7Ref.current;
          // case 7:
          //   return video8Ref.current;
          // case 8:
          //   return video9Ref.current;
          // case 9:
          //   return video10Ref.current;
          default:
            return null;
        }
      case 'result':
        return null;
      default:
        return null;
    }
  };

  useEffect(() => {
    // console.log('screen:', screen);
    // console.log('index:', questionIndex);
    // console.log('correctAnswer:', correctAnswer);
    if (screen === 'questions') {
      if (prevScreen !== screen && screen === 'questions') {
        //moved to first question
        video1Ref.current.play();
      } else if (questionIndex !== prevQuestionIndex) {
        //moved to next question
        currentVideo().play();
      } else if (prevCorrectAnswer !== correctAnswer && correctAnswer === true) {
        //correct answer
        currentVideo().currentTime = questionIndex === 0 ? 8.8 : 7.8;
        // currentVideo().play();
      } else if (prevCorrectAnswer !== correctAnswer && correctAnswer === false) {
        //wrong answer
        currentVideo().currentTime = questionIndex === 0 ? 6.5 : 5.5;
        //currentVideo().play();
      }
    }
  }, [screen, correctAnswer, questionIndex]);

  const videoController = (number, event) => {
    // console.log(event.target.currentTime);
    if (number === 1) {
      if (correctAnswer === null && screen === 'home' && event.target.currentTime >= 2) {
        //home screen intro
        video1Ref.current.pause();
      } else if (correctAnswer === null && screen === 'questions' && event.target.currentTime >= 4.9) {
        //question screen intro
        video1Ref.current.pause();
      } else if (correctAnswer === true && screen === 'questions' && event.target.currentTime >= 7.5) {
        //pause on turquoise screen - correct answer
        video1Ref.current.pause();
      } else if (correctAnswer === false && screen === 'questions' && event.target.currentTime >= 5.5) {
        //pause on blue screen - wrong answer
        video1Ref.current.pause();
      }
    } else {
      if (correctAnswer === null && screen === 'questions' && event.target.currentTime >= 3.7) {
        //pause on turquoise screen - question
        currentVideo().pause();
      } else if (correctAnswer === true && screen === 'questions' && event.target.currentTime >= 6.8) {
        //pause on turquoise screen - correct answer
        currentVideo().pause();
      } else if (correctAnswer === false && screen === 'questions' && event.target.currentTime >= 5.5) {
        //pause on blue screen
        currentVideo().pause();
      }
    }
  };

  return (
    <div className='container' style={{ transform: `translate(-50%, -50%) scale(${scale})` }} ref={containerRef}>
      {/* video backgrounds */}
      <video
        ref={video1Ref}
        autoPlay
        muted
        playsInline
        onTimeUpdate={(event) => videoController(1, event)}
        className={`video-background ${screen === 'home' || questionIndex === 0 ? 'active' : ''}`}
        src={require('./assets/videos/ScholarRock_Update_Question_01_05.mp4')}
      />
      <video
        ref={video2Ref}
        preload={'auto'}
        muted
        playsInline
        onTimeUpdate={(event) => videoController(2, event)}
        className={`video-background ${questionIndex === 1 ? 'active' : ''}`}
        src={require('./assets/videos/ScholarRock_Update_Question_02_05.mp4')}
      />
      <video
        ref={video3Ref}
        preload={'auto'}
        muted
        playsInline
        onTimeUpdate={(event) => videoController(3, event)}
        className={`video-background ${questionIndex === 2 ? 'active' : ''}`}
        src={require('./assets/videos/ScholarRock_Update_Question_03_05.mp4')}
      />
      <video
        ref={video4Ref}
        preload={'auto'}
        muted
        playsInline
        onTimeUpdate={(event) => videoController(4, event)}
        className={`video-background ${questionIndex === 3 ? 'active' : ''}`}
        src={require('./assets/videos/ScholarRock_Update_Question_04_05.mp4')}
      />
      <video
        ref={video5Ref}
        preload={'auto'}
        muted
        playsInline
        onTimeUpdate={(event) => videoController(5, event)}
        className={`video-background ${questionIndex === 4 ? 'active' : ''}`}
        src={require('./assets/videos/ScholarRock_Update_Question_05_05.mp4')}
      />
      <video
        ref={video6Ref}
        preload={'auto'}
        muted
        playsInline
        onTimeUpdate={(event) => videoController(6, event)}
        className={`video-background ${questionIndex === 5 ? 'active' : ''}`}
        src={require('./assets/videos/ScholarRock_Update_Question_06_05.mp4')}
      />
      {/* <video
        ref={video7Ref}
        preload={'auto'}
        muted
        playsInline
        onTimeUpdate={(event) => videoController(7, event)}
        className={`video-background ${questionIndex === 6 ? 'active' : ''}`}
        src={require('./assets/videos/ScholarRock_Question_07_02.mp4')}
      />
      <video
        ref={video8Ref}
        preload={'auto'}
        muted
        playsInline
        onTimeUpdate={(event) => videoController(8, event)}
        className={`video-background ${questionIndex === 7 ? 'active' : ''}`}
        src={require('./assets/videos/ScholarRock_Question_08_02.mp4')}
      />
      <video
        ref={video9Ref}
        preload={'auto'}
        muted
        playsInline
        onTimeUpdate={(event) => videoController(9, event)}
        className={`video-background ${questionIndex === 8 ? 'active' : ''}`}
        src={require('./assets/videos/ScholarRock_Question_09_02.mp4')}
      />
      <video
        ref={video10Ref}
        preload={'auto'}
        muted
        playsInline
        onTimeUpdate={(event) => videoController(10, event)}
        className={`video-background ${questionIndex === 9 ? 'active' : ''}`}
        src={require('./assets/videos/ScholarRock_Question_10_02.mp4')}
      /> */}

      <header>
        <div className='logo' />
      </header>
      <main>
        {screen === 'home' && <Home />}
        {screen === 'questions' && <Questions />}
        {screen === 'result' && <Result />}
      </main>
      <footer>
        <div className='content'>
          <div className='scholar-rock-logo' />
          <div className='copyright'>
            <p>©2023 Scholar Rock, Inc. All Rights Reserved.</p>
            <p>This website is intended for SCHOLAR ROCK employees only</p>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
