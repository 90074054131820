import {useRecoilState} from "recoil";
import {correctAnswerState, currentIndexState, scoreState, screenState} from "../state";
import QuestionData from '../data.json';
import '../styles/questions.scss';
import {CSSTransition, SwitchTransition} from "react-transition-group";
import {useEffect} from "react";

const Questions = () => {
    const [screen, setScreen] = useRecoilState(screenState);
    const [score, setScore] = useRecoilState(scoreState);
    const [currentIndex, setCurrentIndex] = useRecoilState(currentIndexState);
    const [isCorrect, setIsCorrect] = useRecoilState(correctAnswerState);

    const selectAnswer = (answer) => {
        const correctAnswer = QuestionData[currentIndex].correctAnswer === answer;
        if (correctAnswer) {
            //correct answer - update score
            setScore(scoreInt => scoreInt + 1);
        }
        setIsCorrect(correctAnswer);
    }

    useEffect(() => {
        //force the animation to trigger on first render
        setIsCorrect(1);
        setTimeout(() => {
            setIsCorrect(null);
        }, 10);
    }, []);

    const nextButton = () => {
        const factLength = QuestionData.length;
        const currentLength = currentIndex + 1;

        if (currentLength === factLength) {
            //finished, go to result/leaderboard screen
            setScreen('result');
        } else {
            //go to next fact
            setCurrentIndex(index => index + 1);
            setIsCorrect(null);
        }
    }

    return (
        <div className="view questions">
            <div className="card-container">
                <div className="question-count"><span>{currentIndex + 1}</span> OF {QuestionData.length}</div>
                <Card data={QuestionData[currentIndex]} isCorrect={isCorrect}/>
                <SwitchTransition>
                    <CSSTransition key={isCorrect === null ? 'question' : 'result'} timeout={{enter: 2500, exit: 0}}
                                   classNames="fade">
                        {isCorrect === null ?
                            <div className="answer-buttons">
																{QuestionData[currentIndex].options.map((option, i) => <div key={i} className="button" onClick={() => selectAnswer(option)}>{option}</div>)}
                            </div>
                            :
                            <div className="answer-buttons">
                                <div className={`button next ${isCorrect ? 'correct' : 'wrong'}`}
                                     onClick={nextButton}>{currentIndex === 0 ? "Rock on to the next question!" : "rock on"}</div>
                            </div>
                        }
                    </CSSTransition>
                </SwitchTransition>
                {isCorrect !== null &&
                    <div className="references">
                        <p className="title">References:</p>
                        <ol>
                            {QuestionData[currentIndex].answer.references.map((reference, index) => <li key={index}
                                                                                                        dangerouslySetInnerHTML={{__html: reference}}></li>)}
                        </ol>
                    </div>}
            </div>
        </div>
    )
}

const Card = ({data, isCorrect}) => {
    const sidePiece = () => {
        //calculate the content to show on the right side
        if (!data.answer.sidePiece) return null;

        if (data.answer.sidePiece.type === "quote") {
            return (
                <div className={`quote ${data.answer.sidePiece.isLarge ? 'large' : ''}`}
                     style={{height: data.answer.sidePiece.height, width: data.answer.sidePiece.width}}>
                    <p className="quote-text" dangerouslySetInnerHTML={{__html: data.answer.sidePiece.text}}/>
                    <p className="author" dangerouslySetInnerHTML={{__html: data.answer.sidePiece.author}}/>
                </div>
            );
        } else if (data.answer.sidePiece.type === "fact") {
            return (
                <div className="quote fact"
                     style={{height: data.answer.sidePiece.height, width: data.answer.sidePiece.width}}>
                    <p dangerouslySetInnerHTML={{__html: data.answer.sidePiece.text}}/>
                </div>
            );
        } else if (data.answer.sidePiece.type === "block") {
            return (
                <div className="text-block"
                     style={{height: data.answer.sidePiece.height, width: data.answer.sidePiece.width}}>
                    <p dangerouslySetInnerHTML={{__html: data.answer.sidePiece.text}}/>
                </div>
            );
        } else {
            return null;
        }
    }

    if (isCorrect === null) {
        //show fact question
        return (
            <div className="question-card" dangerouslySetInnerHTML={{__html: data.question}} />
        )
    } else {
        return (
            <div
                className={`result-card correct ${isCorrect ? 'correct' : 'wrong'} ${data.answer.sidePiece && data.answer.sidePiece.type}`}>
                <h1>{isCorrect === true ? "SMASHING IT!" : "OOOPS, NOT QUITE!"}</h1>
                <div className={`content ${data.answer.longBox ? 'long' : ''}`}>
                    <p dangerouslySetInnerHTML={{__html: data.answer.text}}/>
                    {/* {sidePiece()} */}
                </div>
            </div>
        )
    }
}

export default Questions;
