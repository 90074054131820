import { useRecoilState } from 'recoil';
import { emailState, screenState } from '../state';
import '../styles/home.scss';
import { useState } from 'react';

const Home = () => {
  const [showNote, setShowNote] = useState(true);

  const [screen, setScreen] = useRecoilState(screenState);
  const [email, setEmail] = useRecoilState(emailState);

  const updateEmail = (event) => setEmail(event.currentTarget.value);
  const isDisabled = !email.endsWith('@scholarrock.com') ? 'disabled' : '';

  return (
    <div className='view home'>
      <h1>
        Is your knowledge of spinal muscular
        <br />
        atrophy (SMA) Scholar Rock-solid?
      </h1>
      <h2>
        Find out by taking part in our ROCK SMArter quiz in honor of SMA
        <br />
        disease awareness month, August 2023.
      </h2>
      <div className='split-box'>
        <div className='sma-logos' />
        <p>
          For every Rocker that completes the quiz,
          <br />
          we’ll donate $100 split between Cure SMA
          <br />
          and SMA Europe!*
        </p>
      </div>
      <h3>
        With 6 multiple choice questions in less than 10 minutes, you can learn more about SMA and the community we serve, build awareness of SMA with your
        peers, and discover why it’s so important to help support people living with SMA.
      </h3>
      <div className='rock-on-container'>
        <h1>Ready to rock and roll?</h1>
        <h3>Enter your Scholar Rock email address to get started:</h3>
        <input
          type='text'
          value={email}
          onFocus={(e) => (e.target.placeholder = '')}
          onBlur={(e) => (e.target.placeholder = 'Type Email (for authentication purposes only)')}
          onChange={updateEmail}
          placeholder='Type Email (for authentication purposes only)'
          maxLength={50}
        />
        <div className={`button ${isDisabled ? 'disabled' : ''}`} onClick={() => setScreen('questions')}>
          Rock on!
        </div>
      </div>

      <p className='footnote'>
        *A maximum of $10,000 will be donated to Cure SMA and SMA Europe (up to $5,000 each). Only one donation will be made per person completing the quiz.
      </p>

      {showNote && (
        <div className='orange-note'>
          <div className='close-icon' onClick={() => setShowNote(false)} />
          <h1>Did you know?</h1>
          <p>
            Scholar rocks occur naturally when softer rock erodes away from seams of hard rock over time. They are appreciated by Chinese scholars who believe
            they reveal inner strength and truth. Watch the scholar rock reveal itself as your knowledge of SMA deepens.
          </p>
        </div>
      )}
    </div>
  );
};

export default Home;
