import { useRecoilState } from 'recoil';
import { emailState, scoreState } from '../state';
import QuestionData from '../data.json';
import '../styles/result.scss';
import { useEffect, useState } from 'react';
import { DataStore } from '@aws-amplify/datastore';
import { Entry } from '../models';

const Result = () => {
  const [email] = useRecoilState(emailState);
  const [score] = useRecoilState(scoreState);
  const [showNote, setShowNote] = useState(true);

  useEffect(() => {
    if (email) {
      DataStore.save(
        new Entry({
          email: email,
        })
      );
    }
  }, []);

  return (
    <div className='view result'>
      <h1>
        Congratulations
        <br />
        You rock!
      </h1>
      <div className='score-label'>YOU SCORED:</div>
      <div className='score-split'>
        <span className='score'>
          {score} out of {QuestionData.length}
        </span>{' '}
        <span className='button restart' onClick={() => window.location.reload()}>
          rock again
        </span>
      </div>
      <div className='split-box'>
        <div className='sma-logos' />
        <p>
          Thank you for completing ROCK SMArt!
          <br />
          On your behalf, we are donating $100 split between Cure <br />
          SMA and SMA Europe* to help support the SMA community.
        </p>
      </div>
      <p className='sma-month'>
        Do your part for SMA awareness month. You rocked the quiz but it’s just a small part of our mission to make a difference in SMA. Keep on learning, keep
        on pushing forwards and keep our patients at the heart of everything we do.
      </p>
      <h3>Want to find out more about SMA?</h3>
      <div className='cta-buttons'>
        <a href='https://www.curesma.org/' className='button' target='_blank'>
          Visit Cure SMA
        </a>
        <a href='https://www.sma-europe.eu/' className='button' target='_blank'>
          Visit SMA Europe
        </a>
      </div>
      <p className='footnote'>
        *A maximum of $10,000 will be donated to Cure SMA and SMA Europe (up to $5,000 each). Only one donation will be made per person completing the quiz.
      </p>

      {showNote && (
        <div className='orange-note'>
          <div className='close-icon' onClick={() => setShowNote(false)} />
          <p>
            For us, the Scholar Rock <br />
            represents our quest to <br />
            discover breakthrough <br />
            medicines for our patients.
          </p>
        </div>
      )}
    </div>
  );
};

export default Result;
